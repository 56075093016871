<template lang="pug">
  v-dialog(
    v-model="open"
    width="800"
    eager
  )
    v-card
      v-toolbar(
        :color="editMode ? 'yellow darken-3' : 'primary darken-3'"
        dark
        dense
        flat
      )
        span {{ stockId }} Units
      v-container
        v-row
          v-col(cols="3")
            v-row
              v-col(cols="12")
                v-combobox(
                  :disabled="editMode"
                  :items="unitIndexList"
                  :loading="unitIndexGetting"
                  v-model="unit"
                  dense
                  label="unit"
                  item-value="id"
                  item-text="description"
                  :return-object="false"
                  :error-messages="stockUnitStorePostErrors.unit"
                )
                  template(v-slot:selection="{ item }")
                    span {{ item.id || item }}
                  template(v-slot:item="{ item }")
                    span(v-if="item.id").primary--text 
                      | {{ item.id }}
                      small.ml-2.blue-grey--text {{ item.description }}
                    span(v-else) {{ item }}
              v-col(cols="12")
                v-text-field(
                  dense
                  label="srp"
                  type="number"
                  v-model="srp"
                  :error-messages="stockUnitStorePostErrors.srp"
                )
              v-col(cols="12" v-if="!editMode")
                v-btn(
                  dense
                  dark
                  block
                  color="green"
                  @click="storeUnit"
                  :loading="stockUnitStorePosting"
                )
                  span Add
              v-col(cols="12" v-else)
                v-row
                  v-col(cols="6")
                    v-btn(
                      dense
                      dark
                      block
                      color="yellow darken-3"
                      @click="updateUnit"
                      :loading="stockUnitStorePosting"
                    )
                      span Update
                  v-col(cols="6")
                    v-btn(
                      dense
                      dark
                      block
                      color="red"
                      @click="defaultData = {}"
                    )
                      span Cancel
          v-col(cols="9")
            v-simple-table(dense)
              template(v-slot:default)
                thead
                  tr
                    th Unit
                    th SRP
                    th Config
                tbody
                  tr(v-if="stockUnitGetting")
                    td(colspan="3").text-center
                      v-progress-circular(
                        size="20"
                        color="primary"
                        indeterminate
                      )
                      | Loading...
                  tr(v-for="(item, index) in stockUnitList" :key="index")
                    td {{ item.unit }}
                    td {{ item.srp.phpFormat() }}
                    td
                      v-btn(
                        color="yellow darken-3"
                        text
                        dense
                        small
                        @click="defaultData = item"
                      )
                        v-icon mdi-pencil
</template>
<script>
import unitRepository from '@/repositories/unit.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import stockRepository from '@/repositories/stock.repository'
import { requestVars } from '@/libs/api-helper.extra'

const [unitIndexVars, unitIndexVarNames] = requestVars({ identifier: 'unit-index' })
const [stockUnitStoreVars, stockUnitStoreVarNames] = requestVars({ identifier: 'stock-unit-store', request: 'post', hasData: false })
const [stockUnitVars, stockUnitVarNames] = requestVars({ identifier: 'stock-unit' })

const unitIndexHandler = new VueRequestHandler(null, unitIndexVarNames)
const stockUnitStoreHandler = new VueRequestHandler(null, stockUnitStoreVarNames)
const stockUnitHandler = new VueRequestHandler(null, stockUnitVarNames)

const inputVars = () => ({
  srp: null,
  unit: null,
})

export default {
  name: 'StockUnit',
  props: {
    stockId: String,
  },
  data () {
    return {
      ...unitIndexVars,
      ...stockUnitStoreVars,
      ...stockUnitVars,
      ...inputVars(),
      defaultData: {},
    }
  },
  created () {
    this.getUnitIndex()
  },
  computed: {
    open: {
      get () {
        return Boolean(this.stockId)
      },
      set (val) {
        if (val) return
        this.$emit('update:stock-id', null)
      },
    },
    editMode () {
      return !this.$objectEmpty(this.defaultData)
    },
  },
  watch: {
    defaultData () {
      const input = inputVars()
      this.$populateInputData({ input })
    },
    stockId (val) {
      if (!val) return
      this.getUnits()
    },
  },
  methods: {
    getUnitIndex () {
      const handler = unitIndexHandler
      const repository = unitRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    async storeUnit () {
      const confirm = await this.unitConfirm()
      if (!confirm) return
      const handler = stockUnitStoreHandler
      const repository = stockRepository.storeUnits
      const id = this.stockId
      const data = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data])
        .then(() => this.$resetInputData(inputVars()))
    },
    async updateUnit () {
      const confirm = await this.unitConfirm(false)
      if (!confirm) return
      const handler = stockUnitStoreHandler
      const repository = stockRepository.updateUnits
      const id = this.stockId
      const data = this.$getInputData(inputVars())
      handler.setVue(this)
      handler.execute(repository, [id, data])
        .then(() => this.$resetInputData(inputVars()))
    },
    unitConfirm (store = true) {
      if (store) {
        const config = { title: 'Store Unit', icon: 'mdi-save', color: 'green' }
        return this.$confirm('are you sure you want to store this unit?', config)
      }
      const config = { title: 'Update Unit', icon: 'mdi-pencil', color: 'yellow darken-3' }
      return this.$confirm('are you sure you want to update this unit?', config)
    },
    getUnits () {
      const handler = stockUnitHandler
      const repository = stockRepository.units
      handler.setVue(this)
      handler.execute(repository, [this.stockId])
    },
  },
}
</script>